<template>
  <ion-page class="page scrollable">
    <TopBar />
    <div class="header p-3">
      <h1 class="title">Content Preferences</h1>
      <div class="change-title">General</div>

      <div class="d-flex mt-3 pl-2">
        <ion-checkbox
          :disabled="user.restrictions?.show_nsfw ? user.restrictions?.show_nsfw : !isOver18"
          v-model="userData.show_nsfw"
          class="mr-1"
        ></ion-checkbox>
        <ion-label style="text-align: left" :style="user.restrictions?.show_nsfw || !isOver18 ? 'color: grey' : ''">
          <strong> Show Mature content: </strong>By checking this box,
          <strong>I acknowledge that I am 18 years old or older.</strong>
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
            >(see content guidelines for definition of Mature)</a
          ></ion-label
        >
      </div>
      <div
        v-if="user.restrictions?.show_nsfw || !isOver18"
        class="d-flex align-items-center mx-2 mb-4"
        style="color: grey"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="text-align: left; font-size: 10px" v-if="user.restrictions?.show_nsfw">
          You are not authorized to take this action. If you think it's a mistake reach out to us on
          <a href="mailto:moderation@characterhub.com" style="color: grey">
            <strong class="clickable-item-hov">"moderation@characterhub.com"</strong>
          </a>
        </p>
        <p style="text-align: left; font-size: 10px" v-else-if="!isOver18">
          You must be at least 18 years old to modify these settings.
          <router-link :to="`/profile/${user.username}`" target="_blank">
            <strong class="clickable-item-hov">Update age in profile settings</strong>
          </router-link>
        </p>
      </div>

      <div class="d-flex mt-3 pl-2">
        <ion-checkbox
          :disabled="user.restrictions?.is_nsfw ? user.restrictions?.is_nsfw : !isOver18"
          v-model="userData.is_nsfw"
          class="mr-1"
        ></ion-checkbox>
        <ion-label style="text-align: left" :style="user.restrictions?.is_nsfw || !isOver18 ? 'color: grey' : ''">
          <strong> Mark my entire profile as Mature: </strong>This restricts your profile visibility to
          <strong>adults only. By selecting this you confirm that you are 18 years old or older.</strong>
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
            >(see content guidelines for definition of Mature)</a
          >
        </ion-label>
      </div>
      <div
        v-if="user.restrictions?.is_nsfw || !isOver18"
        class="d-flex align-items-center mx-2 mb-4"
        style="color: grey"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="text-align: left; font-size: 10px" v-if="user.restrictions?.is_nsfw">
          You are not authorized to take this action. If you think it's a mistake reach out to us on
          <a href="mailto:moderation@characterhub.com" style="color: grey">
            <strong class="clickable-item-hov">"moderation@characterhub.com"</strong>
          </a>
        </p>
        <p style="text-align: left; font-size: 10px" v-else-if="!isOver18">
          You must be at least 18 years old to modify these settings.
          <router-link :to="`/profile/${user.username}`" target="_blank">
            <strong class="clickable-item-hov">Update age in profile settings</strong>
          </router-link>
        </p>
      </div>

      <div class="w-100 d-flex">
        <ion-button class="mx-auto mt-4 publish-btn" :disabled="isSaving" @click="save">
          <ChLoading size="sm" v-if="isSaving" class="spinner" />
          <span v-else>Save</span>
        </ion-button>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Getter, Action } from 's-vuex-class';
import namespace from '@/shared/store/namespace';
import { User } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { warningOutline } from 'ionicons/icons';
import TopBar from '@/shared/components/TopBar.vue';
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';

@Options({
  components: {
    TopBar,
  },
})
export default class Usersettings extends Vue {
  @Action('byId', { namespace: namespace.UsersModule })
  getUserById!: (id: string) => User;

  @Getter('byUsername', { namespace: namespace.UsersModule })
  userByUsername!: (username: string) => User;

  @Action('getUserByUsername', { namespace: namespace.UsersModule })
  public getUserByUsername!: any;

  public warningOutline = warningOutline;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get isOver18() {
    return this.user?.over_18 !== false;
  }

  public isSaving = false;

  public userData = {
    is_nsfw: false,
    show_nsfw: false,
  };

  public async save() {
    const { updateContent } = authStore();

    if (!this.userData.is_nsfw && !this.userData.show_nsfw) {
      const alert = await alertController.create({
        cssClass: 'welcome-rc-alert',
        header: 'Are you sure?',
        message: `Are you sure you want to change your content preferences bracket? Doing this will result in following:
        <ul>
          <li>All your mature worlds / social spaces/posts/characters (if any) will turn private.</li>
          <li>All your  mature marketplaces will be deactivated.</li>
          <li>You will no longer be able to see / post any mature content.</li>
          </ul>`,
        buttons: [
          {
            text: 'Cancel',
            id: 'disagree-button',
            role: 'notok',
            cssClass: 'mx-auto text-danger',
          },
          {
            text: 'Agree',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'mx-auto',
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role !== 'ok') {
        return;
      }
    }

    this.isSaving = true;
    try {
      await updateContent(this.userData);
    } catch (error) {
      toast.show(
        'You might have some nsfw content published. Please modify it and try again later.',
        'nonative',
        'danger'
      );
    }
    this.isSaving = false;
  }

  public async mounted() {
    const { user, hydrateUser } = authStore();
    hydrateUser();
    this.userData = pick(user.value, ['is_nsfw', 'show_nsfw']);
  }
}
</script>

<style lang="sass" scoped>
.hint
  font-size: 14px
  text-align: start
  margin-top: 10px
.c-input
  text-align: start
.change-title
  width: 100%
  text-align: start
  font-weight: bold
  font-size: 18px
.c-input
  text-align: start
.header
  text-align: center

.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
.title
  text-align: center
  font-size: 24px
</style>
